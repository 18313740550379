<template>

    <div>

        <v-container fluid>

            <v-form ref="form">
                <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                    <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Company Details</v-subheader>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-col style="padding: 0px;">
                        <v-select 
                            color="csb_yellow"
                            v-model="company_type_input"
                            :rules="[rules.required]"
                            :items="company_type" 
                            item-text="display" 
                            item-value="value" 
                            label="Type of Company" 
                            clearable>
                        </v-select>
                    </v-col>
                </v-row>

                <v-row align="center">
                    <v-text-field
                        ref="company_name"
                        v-model="company_name"
                        :rules="[rules.required]"
                        color="csb_yellow"
                        label="Company Name">
                    </v-text-field>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-text-field
                        ref="company_phone_number"
                        prefix="+632"
                        pattern="\d*" 
                        type="tel"
                        counter="8"
                        v-model="company_phone_number"
                        :rules="[rules.required, rules.company_phone_number_length]"
                        color="csb_yellow"
                        label="Company Phone Number"
                        @keydown="isNumber('company_phone_number')"
                        required>
                    </v-text-field>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-text-field
                        ref="company_mobile_number"
                        prefix="+63"
                        pattern="\d*" 
                        type="tel"
                        counter="10"
                        v-model="company_mobile_number"
                        :rules="[rules.company_mobile_number_prefix]"
                        color="csb_yellow"
                        label="Company Mobile Phone Number"
                        @keydown="isNumber('company_mobile_number')">
                    </v-text-field>
                </v-row>

                <v-row align="center">
                    <v-text-field
                        ref="company_website"
                        v-model="company_website"
                        :rules="[rules.required]"
                        color="csb_yellow"
                        label="Company Website/Online Page Link">
                    </v-text-field>
                </v-row>

                <v-row align="center" class="mb-4" style="border-bottom: 1px dashed; border-color: #4f2684;"> 
                    <v-subheader style="padding: 0px !important; font-size: 18px; font-weight: 400; color: #4f2684; margin-bottom: -8px;">Company Address</v-subheader>
                </v-row>

                <v-row align="center">
                    <v-autocomplete 
                        autocomplete="province"
                        ref="province"
                        :rules="[rules.required]"
                        :loading="province_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="province" 
                        :items="province_options" 
                        :search-input.sync="search_province"
                        item-text="Title" 
                        item-value="MappingId" 
                        label="Province"
                        @change="provinceOnChange()"
                        >
                    
                    </v-autocomplete>
                </v-row>

                <v-row align="center" style="margin-top: -6px;">
                    <v-autocomplete 
                        autocomplete="city"
                        ref="city"
                        :rules="[rules.required]"
                        :loading="city_loading"
                        hide-no-data
                        hide-selected
                        color="csb_yellow"
                        clearable 
                        v-model="city" 
                        :items="city_options" 
                        :search-input.sync="search_city"
                        item-text="Title" 
                        item-value="MappingId" 
                        label="City"
                        :disabled="disable_city"
                        >
                    
                    </v-autocomplete>
                </v-row>

                <v-row align="center">
                    <v-text-field
                        ref="street"
                        v-model="street"
                        :rules="[rules.required]"
                        color="csb_yellow"
                        label="Street/Building Name"
                        required>
                    </v-text-field>
                </v-row>


            </v-form>
        </v-container>
      
        <v-row  align="center" justify="space-around" class="bottom-nav">
            <v-col class="bottom-nav-item">
                <v-btn @click="handleBack()" depressed class="bottom-nav-btn back-btn">
                    Back
                </v-btn>
            </v-col>
            <v-col class="bottom-nav-item">
                <v-btn @click="handleNext()" depressed class="bottom-nav-btn">
                    Next
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import { getLookupValues } from '@/api/profile'
import { mapGetters } from 'vuex'

const validPrefixes = [
    '905','906','915','916','917','926','927','935','936','945','953','954','955','956','963','964','965','966','967', '',
    '975','976','977','994','995','996','997','817','813','900','907','908','909','910','911','912','913','914','918','919','920','921','928',
    '929','930','938','939','940','946','947','948','949','950','951','957','958','959','960','961','968','969','970','971','980','981','982','985',
    '989','992','998','999','922','923','924','925','931','932','933','934','941','942','943','944','951','952','962','971','972',

]

const mustBeValidPrefix = (value) => {
    var prefix = value.substring(0, 3);
    var res = validPrefixes.indexOf(prefix) > -1
    return res
}
   

export default {
    computed: {

        ...mapGetters([
            'profileFormIsValid',
            'addressFormIsValid'
        ]),
    },
    beforeRouteLeave(to, from, next) {

        if(to.name == 'Company'){

            var companyDetailsFormIsValid = this.$refs.form.validate()
            this.$store.dispatch('user/companyDetailsFormIsValid', companyDetailsFormIsValid)
            next()
            
        } else {

            if(this.$refs.form.validate()){
                
                if(to.name == 'Profile'){
                    next()
                } else if(to.name == 'Address'){
                    if(this.profileFormIsValid == true){
                        next();
                    }
                } else if(to.name == 'Attachment'){
                    if(this.profileFormIsValid && this.addressFormIsValid){
                        next();
                    }
                }
                
                this.$store.dispatch('user/companyDetails', this.$data)

            }
        } 
      
    },
  
    data: function() {
        return {
            company_type_input: '',
            company_name: '',
            company_phone_number: '',
            company_mobile_number: '',
            company_website: '',

            company_type: [{display: 'Sole Proprietor', value: 'Sole Proprietor'}, {display: 'Partnership', value: 'Partnership'}, {display: 'Corporation', value: 'Corporation'}],

            province: null,
            search_province: null,
            province_options: [],
            city: null,
            search_city: null,
            city_options: [],
            disable_city: true,
            street: '',

            province_loading: true,
            city_loading: false,

            //rules
            rules: {
                required: v => !!v || 'Required.',
                company_phone_number_length:  v => v.length == 8 || 'Mobile no must be 8 digits',
                company_mobile_number_prefix:  v => mustBeValidPrefix(v) || 'Invalid mobile no prefix',
            },
        }
    },
  
    methods:{
        handleBack(){

            this.$store.dispatch('user/navVisibility', false) // show nav
            this.$router.push({ path: '/company' })

        },
        handleNext(){

            this.$router.push({ path: '/profile/index' })
        },

        fetchLookupValues(value, id=''){
            return getLookupValues(value, id).then(res => {
                return res.data.data
                
            })
        },

        provinceOnChange(){

            if(this.province) {
                this.disable_city = false
                this.city = null
                this.$refs.city.reset()
                this.city_loading = true
                this.fetchLookupValues('city', this.province).then(res => {
                    var cities = res
                    cities.sort((a,b) => (a.Title > b.Title) ? 1 : ((b.Title > a.Title) ? -1 : 0)); // sort by name
                    this.city_options = cities
                    this.city_loading = false
                })
            } else {

                this.disable_city = true
                this.city = null
                this.city_options = []

            }

        },

        isNumber: function(field){
            var charCode = (event.which) ? event.which : event.keyCode;
            
            if ((charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) && charCode !== 46 && charCode !== 188 && charCode !== 190) {
                event.preventDefault();
            } else {
                event.preventDefault();
                var valueString = event.target.value

                if(charCode==8 || charCode==46)
                    valueString = valueString.slice(0, -1)
               
                else if(field=='company_phone_number'){
                    valueString = valueString + event.key
                    if(valueString.length > 8) return false

                } else if(field=='company_mobile_number'){
                    valueString = valueString + event.key
                    if(valueString.length > 10) return false
                }

                var str = valueString.replace(/[^0-9]/g,'')
                this[field] = str
            }
        },
      
    },
    created() {

        this.fetchLookupValues('province').then(res => {
            var provinces = res
            provinces.sort((a,b) => (a.Title > b.Title) ? 1 : ((b.Title > a.Title) ? -1 : 0)); // sort by name

            this.province_options = provinces
            this.province_loading = false
        })
    },

}
</script>